/**
 * Hook that calculates the size of the current window and updates whenever the window's `resize` event fires.
 * See: https://usehooks.com/useWindowSize/
 */

import { useEffect, useState } from 'react';

interface WindowSize {
  width?: number;
  height?: number;
}

function useWindowSize(): WindowSize {
  // Initialize state with undefined width/height so server and client renders match.
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize.
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener.
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size.
    handleResize();

    // Remove event listener on cleanup.
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty deps array ensures that effect is only run on mount.

  return windowSize;
}

export default useWindowSize;
