import { memo, useCallback, useState } from 'react';

import { Popup } from '../modal';

interface ShareProps {
  showing: boolean;
  onDismiss: () => void;
}

const Share: React.FC<ShareProps> = (props) => {
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    const phoneText = document.querySelector('#link');
    // eslint-disable-next-line
    // @ts-ignore
    phoneText.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setCopied(true);
  }, []);

  const onDismiss = useCallback(() => {
    props.onDismiss();
    setCopied(false);
  }, [props]);

  return (
    <Popup showing={props.showing} onDismiss={onDismiss} size="partial">
      <div className="relative flex flex-col h-full overflow-scroll">
        <div className="mx-4 grid grid-cols-3 items-center mt-6">
          <img src="/images/x-black.png" className="w-4 h-4 cursor-pointer" alt="dismiss" onClick={onDismiss} />
        </div>

        <div className="mx-4 mt-4 flex flex-col">
          <p className="text-2xl font-medium text-center">Share Postdates</p>
          <p className="text-center text-gray-400 mt-6 mx-2">
            Know someone who just had a rough breakup? Want to give that unreliable friend a subtle nudge to send your
            stuff back? Share the link!
          </p>

          <div className="mx-2 lg:mx-6 mt-10 lg:mb-10 flex items-center">
            <input
              id="link"
              readOnly
              type="text"
              className="focus:outline-none px-4 py-3 select-all bg-gray-100 rounded-full text-center flex-1"
              value="https://postdates.co"
            />
            <p className="ml-4 w-12 text-center font-medium text-pink-400 cursor-pointer" onClick={onCopy}>
              {copied ? 'Copied!' : 'Copy'}
            </p>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default memo(Share);
