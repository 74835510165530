import { memo } from 'react';

import { BadgeColor, Relationship } from '../lib/types';
import FadeImage from './fadeImage';
import { Badge, StyleProps } from './general';

type ScenarioProps = {
  isDesktop?: boolean;
  relationship: Relationship;
  onClick: (relationship: Relationship) => void;
} & StyleProps;

const Scenario: React.FC<ScenarioProps> = ({ isDesktop, relationship, className, onClick }) => {
  return (
    <div className={`flex flex-col cursor-pointer ${className}`} onClick={() => onClick(relationship)}>
      <FadeImage
        asBackground
        className="w-full h-52 lg:h-48 bg-gray-100 bg-cover bg-center bg-no-repeat"
        src={relationship.imageUrl}
      />

      <div className="mt-3">
        <p className={`lg:hidden text-tiny font-semibold text-${relationship.badgeColor}-400 uppercase tracking-wide`}>
          {relationship.badgeType}
        </p>
        <div className="flex items-center">
          <p className="text-sm lg:text-base font-medium text-gray-900">{relationship.name}</p>
          <Badge
            color={relationship.badgeColor || BadgeColor.pink}
            type={relationship.badgeType}
            className="ml-auto hidden lg:block"
          />
        </div>
        <p className="text-xs lg:text-sm text-gray-400 mt-0.5 lg:mt-1 lg:truncate lg:overflow-ellipsis lg:overflow-hidden">
          <span className="text-pink-300 font-medium">Delivery in 24 hours · </span>
          {isDesktop ? relationship.shortDescription : relationship.description}
        </p>
      </div>
    </div>
  );
};

export default memo(Scenario);
