const Disclaimer: React.FC = () => {
  return (
    <div
      className="fixed left-0 bottom-0 right-0 h-16 lg:h-8 flex items-center justify-center px-8"
      style={{ backgroundColor: '#7AF94C' }}
    >
      <p className="text-black text-center text-sm tracking-wide">
        we are no longer a live delivery service and are definitely not affiliated with Postmates lol
      </p>
    </div>
  );
};

export default Disclaimer;
