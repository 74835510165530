import { memo } from 'react';

import { FeaturedCard } from '../lib/types';
import FadeImage from './fadeImage';

interface FeaturedProps {
  card: FeaturedCard;
  onShare: () => void;
  onRoute: (path: string) => void;
}

const Featured: React.FC<FeaturedProps> = ({ card, onShare, onRoute }) => {
  return (
    <FadeImage
      asBackground
      key={card.id}
      className="relative first:ml-4 ml-2 lg:ml-6 min-w-11/12 lg:min-w-half last:pr-4 h-52 lg:h-72 bg-cover bg-center bg-no-repeat bg-clip-content bg-gray-100 cursor-pointer"
      src={card.imageUrl}
      onClick={() => {
        if (card.id === 'refer') {
          onShare();
        } else if (card.external) {
          window.open(card.url);
        } else {
          onRoute(card.url);
        }
      }}
    >
      <p
        className={`absolute left-4 lg:left-6 top-4 lg:top-6 font-medium lg:text-2xl ${
          card.lightText ? 'text-white' : ''
        }`}
      >
        {card.name}
      </p>
      <p
        className={`text-sm absolute left-4 lg:left-6 top-10 lg:top-14 lg:text-sm font-medium lg:uppercase lg:tracking-wide ${
          card.lightText ? 'text-white' : ''
        }`}
      >
        {card.details}
      </p>
    </FadeImage>
  );
};

export default memo(Featured);
