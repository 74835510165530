/**
 * Provide details about the type of relationship that resulted in the Postdate.
 */

import axios from 'axios';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import { useCallback, useEffect, useState } from 'react';

import { CountButton } from '../components/buttons';
import Cart from '../components/cart';
import CurrentDelivery from '../components/current';
import Disclaimer from '../components/Disclaimer';
import Featured from '../components/Featured';
import { Divider } from '../components/general';
import Share from '../components/modals/share';
import Navbar from '../components/navbar';
import Page, { Content } from '../components/page';
import Scenario from '../components/Scenario';
import Settings from '../components/settings';
import Subnav from '../components/Subnav';
import { AUTH_TOKEN_KEY, useAuth } from '../lib/auth';
import { API_URL } from '../lib/constants';
import { checkoutRouteForDelivery, setType } from '../lib/delivery';
import { getFeaturedCards, getRelationships } from '../lib/relationships';
import { DeliveryStatus, DeliveryType, FeaturedCard, Relationship, User } from '../lib/types';
import useDeviceSize from '../lib/useDeviceSize';
import { itemCount } from '../lib/utils';

interface FeedProps {
  featured: FeaturedCard[];
  relationships: Relationship[];
  user?: User;
}

const Feed: React.FC<FeedProps> = ({ featured, relationships, user }) => {
  const router = useRouter();
  const size = useDeviceSize();

  const {
    activeDeliveries,
    delivery,
    deliveryType,
    updateDelivery,
    updateType,
    token,
    updateUser,
    updateActiveDeliveries,
  } = useAuth();

  const [showingModalCart, setShowingModalCart] = useState(false);
  const [showingSidebarCart, setShowingSidebarCart] = useState(false);

  const [showingSettings, setShowingSettings] = useState(false);
  const [showingShare, setShowingShare] = useState(false);

  useEffect(() => {
    if (user) {
      updateUser(user);
    }
  }, [user, updateUser]);

  // Attempt to show active deliveries.
  useEffect(() => {
    async function fetchActive() {
      if (!token) {
        return;
      }

      // Fetching active delivery count.
      try {
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get<{ count: number }>(`${API_URL}/active`, { headers });
        updateActiveDeliveries(data.count || 0);
      } catch (err) {
        console.error('Error fetching active delivery count', err.message);
      }
    }
    fetchActive();
  }, [token, updateActiveDeliveries]);

  const onSelect = useCallback(
    (relationship: Relationship) => {
      // Just route.
      router.push(`/relationship/${relationship.id}`);
    },
    [router]
  );

  const onTypeChange = useCallback(
    (type: DeliveryType) => {
      if (type === deliveryType) {
        return;
      }

      updateType(type);
      if (delivery && delivery.status === DeliveryStatus.draft && delivery.type !== type) {
        updateDelivery(setType(delivery, type));
      }
    },
    [delivery, deliveryType, updateDelivery, updateType]
  );

  const onCheckout = useCallback(() => {
    if (!delivery) {
      console.error('No delivery when attempting to check out');
      return;
    }

    router.push(checkoutRouteForDelivery(delivery));
  }, [delivery, router]);

  const onHideCart = useCallback(() => {
    setShowingModalCart(false);
    setShowingSidebarCart(false);
  }, []);

  const sorted = [...relationships].sort((r1, r2) => {
    if (deliveryType === DeliveryType.request) {
      return r1.rIdx > r2.rIdx ? 1 : -1;
    }
    return r1.sIdx > r2.sIdx ? 1 : -1;
  });

  const items = delivery && delivery.status === DeliveryStatus.draft ? delivery.items || [] : [];
  const count = itemCount(items);

  const onShowSettings = useCallback(() => setShowingSettings(true), []);
  const onShowModalCart = useCallback(() => count > 0 && setShowingModalCart(true), [count]);
  const onShowSidebarCart = useCallback(() => count > 0 && setShowingSidebarCart(true), [count]);
  const onShowShare = useCallback(() => setShowingShare(true), []);
  const onRoute = useCallback((path: string) => router.push(path), [router]);
  const onHideSettings = useCallback(() => setShowingSettings(false), []);
  const onHideShare = useCallback(() => setShowingShare(false), []);

  return (
    <Page className="bg-white">
      <Navbar cartCount={count} onAction={onShowSettings} onShowCart={onShowSidebarCart} />
      <Subnav className="" deliveryType={deliveryType} onTypeChange={onTypeChange} />

      <Content>
        <div className="lg:mt-12">
          <div className="hidden lg:flex flex-col mx-4">
            <p className="text-2xl font-medium">Featured</p>
            <Divider className="mt-3" />
          </div>

          <div className="flex flex-nowrap overflow-x-scroll mt-7 lg:mt-8 lg:mr-4 lg:mb-8">
            {featured.map((card) => (
              <Featured key={card.id} card={card} onRoute={onRoute} onShare={onShowShare} />
            ))}
          </div>
          <Divider className="mx-4 mt-5 lg:hidden" />
        </div>

        <div className={`pt-4 ${items.length > 0 || activeDeliveries > 0 ? 'mb-24' : 'mb-10'}`}>
          <p className="mx-4 text-lg lg:text-2xl font-medium">Choose Your Relationship</p>
          <Divider className="hidden lg:flex mt-3 mx-4" />

          <div className="mx-4 mt-5 lg:mt-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
            {sorted.map((relationship) => (
              <Scenario
                className="mb-10 lg:mb-12"
                key={relationship.id}
                isDesktop={size === 'lg'}
                relationship={relationship}
                onClick={onSelect}
              />
            ))}
          </div>
        </div>

        {items.length > 0 ? (
          <CountButton
            count={count}
            title="View Cart"
            className="uppercase fixed lg:hidden left-6 bottom-5 right-6 w-floating"
            onClick={onShowModalCart}
          />
        ) : null}

        {/* <Cart showing={showingModalCart} onCheckout={onCheckout} onDismiss={onHideCart} type="popup" /> */}
        {/* <Cart showing={showingSidebarCart} onCheckout={onCheckout} onDismiss={onHideCart} type="sidebar" /> */}
        <Settings showing={showingSettings} onDismiss={onHideSettings} />
        <Share showing={showingShare} onDismiss={onHideShare} />
        {/* {user && items.length === 0 && activeDeliveries > 0 ? <CurrentDelivery count={activeDeliveries} /> : null} */}
        <Disclaimer />
      </Content>
    </Page>
  );
};

export default Feed;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    const cookies = nookies.get(ctx);
    const token = cookies[AUTH_TOKEN_KEY];

    let user: User | undefined = undefined;

    if (token) {
      // Attempt to fetch current user.
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.get<{ user?: User }>(`${API_URL}/user`, { headers });
      if (data.user) {
        user = data.user;
      } else {
        nookies.destroy(ctx, AUTH_TOKEN_KEY, { path: '/' });
      }
    }

    const props: FeedProps = {
      featured: getFeaturedCards(),
      relationships: getRelationships(),
    };

    if (user) {
      props.user = user;
    }

    return { props };
  } catch (err) {
    console.error('Error fetching user for feed', err.message);

    const props: FeedProps = {
      featured: getFeaturedCards(),
      relationships: getRelationships(),
    };

    return { props };
  }
};
