/**
 * Hook built upon `useWindowSize` that returns device size classes similar to tailwind's.
 */

import { useEffect, useState } from 'react';

import useWindowSize from './useWindowSize';

type DeviceSize = 'sm' | 'md' | 'lg';

// Update these breakpoints to adjust the device size trigger points.
const TABLET_MIN = 768;
const DESKTOP_MIN = 1024;

function useDeviceSize(): DeviceSize {
  // Default to the largest size.
  const [deviceSize, setDeviceSize] = useState<DeviceSize>('lg');
  const windowSize = useWindowSize();

  // Recalculate when the window size changes.
  useEffect(() => {
    const { width } = windowSize;
    if (!width) {
      // Default to large again.
      setDeviceSize('lg');
    }

    if (width < TABLET_MIN) {
      setDeviceSize('sm');
    } else if (width < DESKTOP_MIN) {
      setDeviceSize('md');
    } else {
      setDeviceSize('lg');
    }
  }, [windowSize]);

  return deviceSize;
}

export default useDeviceSize;
