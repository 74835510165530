import { memo } from 'react';

import { DeliveryType } from '../lib/types';

interface SubnavProps {
  className?: string;
  deliveryType: DeliveryType;
  onTypeChange: (type: DeliveryType) => void;
}

const Subnav: React.FC<SubnavProps> = ({ className, deliveryType, onTypeChange }) => {
  return (
    <div className={`sticky flex flex-shrink-0 left-0 top-18 h-18 w-full border-b z-20 bg-white ${className}`}>
      <div className="flex items-center w-full max-w-5xl h-1/2 mt-4.5 mx-auto px-4 relative">
        <p className="text font-medium cursor-pointer" onClick={() => onTypeChange(DeliveryType.request)}>
          Request stuff
        </p>
        <p className="text text-gray-400 ml-2">or</p>
        <p className="text font-medium ml-2 cursor-pointer" onClick={() => onTypeChange(DeliveryType.send)}>
          Send stuff
        </p>

        <div
          className={`absolute bottom-0 h-2px bg-gradient-to-r from-pink-300 to-purple-300 rounded-full transition-all duration-200 ease-in-out transform ${
            deliveryType === DeliveryType.request ? 'translate-x-0 w-24' : 'translate-x-32 w-20'
          }`}
        />
      </div>
    </div>
  );
};

export default memo(Subnav);
