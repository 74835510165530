import { CSSProperties, memo, ReactNode, useCallback, useEffect, useState } from 'react';

const imageStyle = {
  opacity: '0',
  transition: 'opacity 0.300s ease 0s',
};

const imageLoadedStyle = {
  opacity: '1',
};

interface FadeImageProps {
  asBackground?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  src: string;
  style?: CSSProperties;
}

const FadeImage: React.FC<FadeImageProps> = ({ asBackground, className, children, onClick, src, style }) => {
  const [loaded, setLoaded] = useState(false);

  const onImageLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    // Load image in a new window.Image and update local state when image is loaded.
    const img = new window.Image();
    img.src = src;
    img.onload = onImageLoad;
  }, [src, onImageLoad]);

  return (
    <>
      {asBackground ? (
        <div
          className={className}
          onClick={() => (onClick ? onClick() : '')}
          style={
            loaded
              ? { backgroundImage: `url('${src}')`, ...style, ...imageStyle, ...imageLoadedStyle }
              : { ...style, ...imageStyle }
          }
        >
          {children}
        </div>
      ) : (
        <img
          className={className}
          src={src}
          style={loaded ? { ...style, ...imageStyle, ...imageLoadedStyle } : { ...style, ...imageStyle }}
        />
      )}
    </>
  );
};

export default memo(FadeImage);
