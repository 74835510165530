/**
 * Converts a Google Place API response into our address interface.
 */

import { BASERATE_BY_CITY, DEFAULT_SUBTOTAL, ZIP_COURIERS_WHITELIST } from './constants';
import { Address, Delivery, DeliveryItem, DeliveryResponse, DeliveryType, User, UserDetails } from './types';

export const isValidAddress = (address: Address): boolean => {
  if (typeof address.lineOne == 'undefined') {
    return false;
  }
  if (typeof address.city == 'undefined') {
    return false;
  }
  if (typeof address.state == 'undefined') {
    return false;
  }
  if (typeof address.zip == 'undefined') {
    return false;
  }
  if (typeof address.latitude == 'undefined') {
    return false;
  }
  if (typeof address.longitude == 'undefined') {
    return false;
  }

  return true;
};

export const getAddress = (place: google.maps.places.PlaceResult): Address => {
  let streetNumber = '';
  let streetName = '';
  let city = '';
  let state = '';
  let zip = '';

  place.address_components.forEach((component) => {
    const { types } = component;
    if (types.includes('street_number')) {
      streetNumber = component.short_name;
    }
    if (types.includes('route')) {
      streetName = component.short_name;
    }
    if (types.includes('sublocality_level_1') || types.includes('locality')) {
      city = component.long_name;
    }
    if (types.includes('administrative_area_level_1')) {
      state = component.short_name;
    }
    if (types.includes('postal_code')) {
      zip = component.short_name;
    }
  });

  const address: Address = {
    lineOne: `${streetNumber} ${streetName}`,
    formatted: place.formatted_address,
    city,
    state,
    zip,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    placeId: place.place_id,
  };

  return address;
};

interface StartDeliveryRequest {
  address: Address;
  delivery: Partial<Delivery>;
  items: DeliveryItem[];
  name: string;
  ex: UserDetails;
}

/**
 * Called by the initiator to begin a delivery and send a heads up to the ex.
 */
export const getStartDeliveryPayload = (delivery: Delivery, user?: User): StartDeliveryRequest => {
  const address = delivery.type === DeliveryType.request ? delivery.dropoffAddress : delivery.pickupAddress;
  const items = delivery.items || [];

  let name = delivery.initiator ? delivery.initiator.name || '' : '';
  if (name === '' && user && user.name) {
    name = user.name || '';
  }

  const formattedDelivery = {
    id: delivery.id,
    type: delivery.type,
    status: delivery.status,
    relationshipId: delivery.relationshipId,
    initiatorId: delivery.initiatorId,
    pickupTime: delivery.pickupTime,
    pickupUserId: delivery.pickupUserId,
    dropoffUserId: delivery.dropoffUserId,
    subtotal: delivery.subtotal,
    platformFee: delivery.platformFee,
    total: delivery.total,
    createdAt: delivery.createdAt,
    updatedAt: delivery.updatedAt,
  };

  return {
    address,
    delivery: formattedDelivery,
    items,
    name,
    ex: delivery.ex,
  };
};

interface AcceptDeliveryRequest {
  address: Address;
  delivery: Partial<Delivery>;
  items: DeliveryItem[];
}

/**
 * Called by the ex when they accept a delivery. This sends it back to the initiator to confirm.
 */
export const getAcceptDeliveryPayload = (delivery: Delivery): AcceptDeliveryRequest => {
  const address = delivery.type === DeliveryType.request ? delivery.pickupAddress : delivery.dropoffAddress;
  const items = delivery.items || [];

  const formattedDelivery = {
    id: delivery.id,
    type: delivery.type,
    status: delivery.status,
    shortId: delivery.shortId,
    relationshipId: delivery.relationshipId,
    initiatorId: delivery.initiatorId,
    pickupTime: delivery.pickupTime,
    pickupUserId: delivery.pickupUserId,
    pickupAddressId: delivery.pickupAddressId,
    dropoffUserId: delivery.dropoffUserId,
    dropoffAddressId: delivery.dropoffAddressId,
    subtotal: delivery.subtotal,
    platformFee: delivery.platformFee,
    total: delivery.total,
    createdAt: delivery.createdAt,
    updatedAt: delivery.updatedAt,
  };

  return {
    address,
    delivery: formattedDelivery,
    items,
  };
};

interface ConfirmDeliveryRequest {
  delivery: Partial<Delivery>;
}

/**
 * Called to make final purchase and schedule a Postdate.
 */
export const getConfirmDeliveryPayload = (delivery: Delivery): ConfirmDeliveryRequest => {
  const formattedDelivery = {
    id: delivery.id,
    type: delivery.type,
    status: delivery.status,
    shortId: delivery.shortId,
    relationshipId: delivery.relationshipId,
    initiatorId: delivery.initiatorId,
    pickupTime: delivery.pickupTime,
    pickupUserId: delivery.pickupUserId,
    pickupAddressId: delivery.pickupAddressId,
    dropoffUserId: delivery.dropoffUserId,
    dropoffAddressId: delivery.dropoffAddressId,
    subtotal: delivery.subtotal,
    platformFee: delivery.platformFee,
    total: delivery.total,
    createdAt: delivery.createdAt,
    updatedAt: delivery.updatedAt,
  };

  return { delivery: formattedDelivery };
};

/**
 * Parses the DeliveryResponse API payload into a hydrated delivery object.
 */
export const parseDeliveryResponse = (data: DeliveryResponse): Delivery => {
  const delivery = data.delivery;
  // Populate.
  delivery.courier = data.courier || null;
  delivery.items = data.items || [];
  delivery.pickupAddress = data.pickupAddress;
  delivery.dropoffAddress = data.dropoffAddress;
  delivery.initiator = data.initiator || {};
  delivery.ex = data.ex || {};

  return delivery;
};

export const itemCount = (items: DeliveryItem[]): number => {
  return items.reduce((curr, item) => curr + item.quantity, 0);
};

export function isValidEmail(email: string): boolean {
  return /^.+@.+\..+$/.test(email);
}

/**
 * Gets the service(courier) name for that zip.
 */
export function getServiceName(zip: string): string {
  let serviceName: string;
  ZIP_COURIERS_WHITELIST.forEach((service) => {
    if (service.zips.includes(zip)) {
      serviceName = service.name;
    }
  });

  return serviceName;
}

/**
 * Gets the starting delivery fee by service. If not service is found DEFAULT_SUBTOTAL constant will be returned
 */
export function getBaserate(service: string): number {
  const cityRecord = BASERATE_BY_CITY.find((item) => item.service === service);

  if (!cityRecord) return DEFAULT_SUBTOTAL;
  return cityRecord.baserate;
}
